<template>
  <Layout tituloPagina="Sistema | Ajustes | Network">
    <div class="card">
      <div class="card-header justify-content-between d-flex align-items-center">
        <h4 class="card-title">
          Ajustes de Network
        </h4>
        <div>
          <div class="dropdown">
            <a
              class="dropdown-toggle"
              href="javascript: void(0);"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <eva-icon
                name="more-horizontal-outline"
                data-eva-width="20"
                data-eva-height="20"
                :class="{
                  'fill-dark': $store.state.layout.layoutMode == 'light',
                  'fill-white': $store.state.layout.layoutMode == 'dark'
                }"
              ></eva-icon>
            </a>
            <ul class="dropdown-menu dropdown-menu-end">
              <li>
                <a
                  href="#"
                  class="dropdown-item"
                  onclick="return false"
                  @click="actualizar()"
                  v-if="!bandera_spinner"
                >
                  <i class="mdi mdi-content-save text-success"></i>
                  Actualizar
                </a>
                <span class="dropdown-item disabled" v-if="bandera_spinner">
                  <i class="mdi mdi-concourse-ci mdi-spin"></i>
                  Actualizar
                </span>
              </li>
              <li>
                <a
                  class="dropdown-item" href="#"
                  onclick="return false;" @click="$router.go(-1)"
                >
                  <i class="mdi mdi-close text-dark"></i>
                  Cerrar
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="card-body">
        <h5>CPEs</h5>
        <div class="form-check form-switch form-switch-lg">
          <input
            class="form-check-input"
            type="checkbox" id="cpe-switch-throughput"
            :checked="ajustes.graficas_throughput_cpes"
            @change="
              ajustes.graficas_throughput_cpes = !ajustes.graficas_throughput_cpes
            "
          />
          <label class="form-check-label" for="cpe-switch-throughput">
            Gráficas de throughput
          </label>
        </div>
      </div>
    </div>

    <div class="text-right">
      <div class="btn-group">
        <button class="btn btn-secondary"
          @click="$router.go(-1)">
          <i class="mdi mdi-chevron-left"></i>
          Atras
        </button>
        <button class="btn btn-success" @click="actualizar()" :disabled="bandera_spinner">
          <i
            class="mdi"
            :class="!bandera_spinner ? 'mdi-content-save' : 'mdi-concourse-ci mdi-spin'"
          ></i>
          Actualizar
        </button>
      </div>
    </div>
    <br>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main"
import RouterSrv from '@/services/RouterSrv.js'
import SistemaSrv from '@/services/SistemaSrv.js'

export default {
  name: 'Network',
  components: { Layout },
  data() {
    return {
      ajustes: {
        graficas_throughput_cpes: 0
      },
      bandera_spinner: false
    }
  },

  created: function(){
    var self = this
    
    self.refrescarAjustes()
  },

  methods: {
    actualizar() {
      let self = this

      self.bandera_spinner = true
      
      SistemaSrv.actualizar(self.ajustes).then(response => {
        iu.msg.success('Datos actualizados')
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron actualizar los datos'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        self.bandera_spinner = false
      })

      RouterSrv.resincronizarTodosCpes()
    },

    refrescarAjustes() {
      let self = this

      SistemaSrv.ajustes(['graficas_throughput_cpes']).then(response => {
        let ajustes = response.data

        ajustes.graficas_throughput_cpes = ajustes.graficas_throughput_cpes == true

        Object.assign(self.ajustes, ajustes)
      })
    }
  }
}
</script>

<style scoped>
.form-check-input:checked {
  background-color: #63AD6F;
  border-color: #63AD6F;
}
</style>